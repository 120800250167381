/*--------------------------------------------------------------
5. Home-style-2
--------------------------------------------------------------*/

/* 5.1	wpo-service-section-s2 */

.wpo-service-section.wpo-service-section-s2{
    .slick-slide{
        padding: 0 10px;
    }
    .slick-dots li button:before{
        font-size: 10px;
        color: $theme-primary-color;
    }
    .wpo-service-item {
        padding: 50px 40px;
        text-align: center;
        position: relative;

        @media(max-width:1200px){
          padding:50px 30px;
        }

        .wpo-service-text{
            h2{
                font-size: 25px;
            }
        }

        &::before{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 10px;
            content: "";
            background: transparent;
            transition: all .3s;
        }

        &:hover{
            &::before{
                width: 100%;
            }
        }

        .wpo-service-icon{
            width: unset;
            height: unset;
            line-height: unset;
            background: none;
            position: relative;
            display: inline-block;
            z-index: 1;

            &::before{
                position: absolute;
                left: -20px;
                top: -20px;
                width: 60px;
                height: 60px;
                content: "";
                background: #fff;
                z-index: -1;
                @include rounded-border(50%);
            }

            .fi:before{
                font-size: 60px;
                color: $dark-gray;
            }
        }

        &:nth-child(1){
            &:before{
                background: #a5e2f3;
            }

            .wpo-service-icon{
                &::before{
                    background: #a5e2f3;
                }
            }
        }

        &:nth-child(2){
            &:before{
                background: #fff1b9;
            }

            .wpo-service-icon{
                &::before{
                    background: #fff1b9;
                }
            }
        }

        &:nth-child(3){
            &:before{
                background: #ffb8b8;
            }

            .wpo-service-icon{
                &::before{
                    background: #ffb8b8;
                }
            }
        }
        &:nth-child(4){
            &:before{
                background: #defcf2;
            }

            .wpo-service-icon{
                &::before{
                    background: #defcf2;
                }
            }
        }
    }
    .owl-nav{
        display: none;
    }

    .owl-dots{
        text-align: center;

        button{
            width: 10px;
            height: 12px;
            border-radius: 50%;
            border: 0;
            margin: 5px;
            background-color: #d6d6d6;

            &.active{
                background-color: $theme-primary-color;
            }
        }
    }
}


// wpo-fun-fact-section-s2

.wpo-fun-fact-section.wpo-fun-fact-section-s2{
    background: none;
    .wpo-fun-fact-grids{
        padding: 90px 70px;
        border-radius: 20px;
        box-shadow: 0px 2px 20px 0px rgba(62, 65, 159, 0.09);

        @media(max-width:767px){
          padding: 30px;
          padding-bottom: 0;
        }
    }

    @media(max-width:767px){
        padding-bottom: 0;
      }
}
