.preloader {
	background-color: $theme-primary-color;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 1000;
	overflow: hidden;

	.angular-shape {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		-webkit-transform: rotate(50deg) scale(1,3.5);
		transform: rotate(50deg) scale(1,3.5);

		> div:first-child {
			background: darken($theme-primary-color, 1.0%);
			width: 20%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
		}

		> div:nth-child(2) {
			background: darken($theme-primary-color, 1.0%);
			width: 30%;
			height: 100%;
			position: absolute;
			left: 35%;
			top: 0;
		}

		> div:nth-child(3) {
			background: darken($theme-primary-color, 1.0%);
			width: 20%;
			height: 100%;
			position: absolute;
			left: 80%;
			top: 0;
		}
	}

	.spinner {
		width: 80px;
		height: 80px;
		position: absolute;
		left: 50%;
		top: 50%;
		@include center-by-translating();
	}

	.double-bounce1, .double-bounce2 {
	  width: 100%;
	  height: 100%;
	  border-radius: 50%;
	  background-color: #fff;
	  opacity: 0.6;
	  position: absolute;
	  top: 0;
	  left: 0;
	  
	  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
	  animation: sk-bounce 2.0s infinite ease-in-out;
	}

	.double-bounce2 {
	  -webkit-animation-delay: -1.0s;
	  animation-delay: -1.0s;
	}

	@-webkit-keyframes sk-bounce {
	  0%, 100% { -webkit-transform: scale(0.0) }
	  50% { -webkit-transform: scale(1.0) }
	}

	@keyframes sk-bounce {
	  0%, 100% { 
	    transform: scale(0.0);
	    -webkit-transform: scale(0.0);
	  } 50% { 
	    transform: scale(1.0);
	    -webkit-transform: scale(1.0);
	  }
	}
}